<template>
  <div>
    <div class="key-visual">
      <div class="mask">
        <div class="mouse-arr">
          <img :src="require('@/assets/images/main_mouse.png')" class="mouse" alt="" />
          <img :src="require('@/assets/images/main_mouse_arr.png')" class="arr" alt="" />
        </div>
        <div class="btn-area">
          <button type="button" @click="soundHandler">
            <span>Sound</span>
            <em>
              <img
                v-if="isMuted"
                :src="require('@/assets/images/muted_icon.png')"
                alt="음소거 버튼 이미지"
              />
              <lottie-muted v-else></lottie-muted>
            </em>
          </button>
          <div class="btn-wrap">
            <a href="https://www.behance.net/emotionglobal" target="_blank">Behance</a> <!-- 버튼 추가 -->
            <a href="https://www.facebook.com/emotion.co.kr/" target="_blank">Facebook</a>
            <router-link to="/request">프로젝트 문의</router-link>
          </div>
        </div>
        <div class="video-item">
          <!--          <video autoplay loop>-->
          <!--            <source src="../assets/images/emotion_video.mp4" type="video/mp4" />-->
          <!--          </video>-->
          <video id="vid" muted autoplay loop>
            <source src="../assets/images/emotion_main_video.webm" type="video/webm" />
            <!--            <source src="../assets/images/about/video_pc.mp4" type="video/mp4" />-->
          </video>
        </div>
        <!--<div
          class="bg"
          :style="{
            backgroundImage: `url(${require('@/assets/images/@temp/main_visual.jpg')})`
          }"
        ></div>-->
      </div>
    </div>
    <div class="contents">
      <div class="title-area">
        <strong class="title">
          <span>
            이모션글로벌의<br />
            전문가들은 각기 다른 재능으로<br />
          </span>
          위대함을 만들어 갑니다.
        </strong>
        <router-link to="/about" class="more">View More</router-link>
      </div>
    </div>

    <div v-swiper:mySwiper="swiperOptions">
      <div class="swiper-wrapper swiper-area">
        <div class="swiper-slide swiper-item" v-for="(item, index) in swiperData" :key="index">
          <div class="about-item">
            <span class="thumb">
              <img :src="item.subImagePhysicalName" alt="이미지" />
            </span>
            <strong class="title">
              {{ item.title }}
            </strong>
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
    <div class="contents" id="test1">
      <div class="title-area type2">
        <strong class="sub-title"> Featured Works </strong>
        <router-link to="/works" class="more">View More</router-link>
      </div>
      <ul class="work-list">
        <li v-for="(item, index) in workListData" :key="item.id">
          <router-link :to="`/works/${item.id}`">
            <div class="thumb">
              <img
                :src="
                  imgUrl(
                    index % 3 === 1 ? item.pcBigImagePhysicalName : item.pcSmallImagePhysicalName
                  )
                "
                alt=""
              />
            </div>
            <span class="title" :style="`color:${item.listFont}`">
              <i :style="`background:${item.listFont}`"></i>
              <span>{{ item.projectServiceName }}</span>
            </span>
          </router-link>
        </li>
      </ul>
      <div class="title-area">
        <strong class="title">
          <span>이모션글로벌의 시선으로</span><br />
          세상을 이야기합니다.
        </strong>
        <router-link to="/magazine" class="more">View More</router-link>
      </div>
      <ul class="magazine-list">
        <li v-for="(item, index) in magazineListData" :key="index">
          <router-link :to="`/magazine/${item.id}/?list=${totalLength - index}`">
            <span class="num">
              {{ totalLength - index }}
            </span>
            <strong class="title">
              {{ item.magazineTitle }}
            </strong>

            <span class="date">
              {{ item.reportingDate | dateFormat }}
            </span>
            <span class="desc">
              {{ item.magazineDesc }}
            </span>
            <span class="more">Read More</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import {TweenMax, Power0} from "gsap";
  import {apiWorksList, apiMagazineList} from "@/api";
  import {commonMethods} from "@/utils/common-methods";
  import LottieMuted from "@/components/lottie/muted.vue";

  export default {
    name: "home",
    components: {LottieMuted},
    data() {
      return {
        isMuted: true,
        scene: null,
        swiperOptions: {
          autoplay: true,
          loop: true,
          centeredSlides: true,
          slidesPerView: "auto"
        },

        swiperData: [
          {
            title: "UX / CX Consulting",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/00_UX-CX.png")
          },
          {
            title: "UX Research & Strategy",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/01_Research-Strategy.png")
          },
          {
            title: "Experience Design",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/02_Experience.png")
          },
          {
            title: "UI / GUI Design",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/03_UI-GUI.png")
          },

          {
            title: "Brand Identity Design",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/04_Brand-Identity.png")
          },

          {
            title: "Interaction Design",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/05_Interaction-Design.png")
          },
          {
            title: "2D/3D Motiongraphic",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/06_Motiongraphic.png")
          },
          {
            title: "Creative idea",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/07_Creative-Idea.png")
          },
          {
            title: "Creative Development",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/08_Creative-Development.png")
          },
          {
            title: "Digital Transformation",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/09_Digtal-Transformation.png")
          },
          {
            title: "SNS Marketing",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/10_SNS-Marketing.png")
          },
          {
            title: "Total Management",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/11_Total-Management.png")
          }
        ],
        keyword: "",
        workListData: [],
        magazineListData: [],
        totalLength: 0
      };
    },
    mixins: [commonMethods],
    created() {
      this.workListfetchData();
      this.magazineFetchData();

      // document.querySelector("#vid").play();
    },
    activated() {
      this.init();
    },
    mounted() {
      this.init();
      this.$scrollmagic.addScene(this.scene);
    },
    destroyed() {
      this.scene?.destroy(true);
    },
    methods: {
      soundHandler() {
        this.isMuted = !this.isMuted;
        if (!this.isMuted) {
          document.querySelector("#vid").muted = false;
        } else {
          document.querySelector("#vid").muted = true;
        }
        console.log(this.isMuted);
      },
      init() {
        this.scene?.destroy(true);
        const keyVisual = document.querySelector(".key-visual");
        const tween = TweenMax.to(keyVisual.querySelector(".video-item"), 1, {
          y: keyVisual.clientHeight,
          ease: Power0.easeInOut
        });
        this.scene = this.$scrollmagic
          .scene({
            triggerElement: keyVisual,
            triggerHook: 0,
            duration: keyVisual.clientHeight * 2
          })
          .setTween([tween]);
        this.$scrollmagic.addScene(this.scene);
      },
      async workListfetchData() {
        this.loading = true;
        try {
          const {
            data: {data: response}
          } = await apiWorksList({
            page: 0,
            size: 6,
            keyword: this.keyword
          });
          this.workListData = response.content;
        } catch (error) {
          console.error(error.response);
        }
      },
      async magazineFetchData() {
        this.loading = true;
        try {
          const {
            data: {data: response}
          } = await apiMagazineList({
            page: 0,
            size: 3,
            keyword: this.keyword
          });
          this.magazineListData = response.content;
          this.totalLength = response.totalElements;
        } catch (error) {
          console.error(error.response);
        }
      }
    }
  };
</script>
<style lang="scss" scoped>
  #animate1 {
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 200px;
    background: #000;
    div {
      transform: translateY(-100%);
      height: 100px;
      width: 100%;
      background: #000;
    }
  }
  .key-visual {
    position: relative;
    min-height: 1000px;
    height: 100vh;
    min-width: $globalWidth;
    .mask {
      height: 100vh;
      position: relative;
      overflow: hidden;
    }
    .mouse-arr {
      z-index: 2;
      position: absolute;
      width: 26px;
      bottom: 50px;
      left: 50%;
      margin-left: -13px;
      text-align: center;
      .mouse {
        animation: mouseUpDown 1s infinite linear alternate;
      }
      .arr {
        margin-top: 20px;
      }
    }
    @keyframes mouseUpDown {
      from {
        transform: translateY(5px);
      }

      to {
        transform: translateY(-5px);
      }
    }
    .btn-area {
      display: flex;
      justify-content: space-between;
      z-index: 2;
      position: absolute;
      bottom: 60px;
      width: calc(100% - 160px);
      padding: 0 80px;
      a,
      button {
        border-radius: 27px;
        display: inline-block;
        padding: 0 40px;
        color: #fff;
        line-height: 50px;
        border: 2px solid #ddd;
        background: rgba(0, 0, 0, 0.3);
        font-size: 16px;
      }
      button {
        display: flex;
        span {
          margin-right: 8px;
        }
        em {
          width: 23px;

          img {
            transform: translateY(3px);
          }
        }
      }
      a + a {
        margin-left: 30px;
      }
    }
    /*.contents {
      z-index: 2;
      display: flex;
      flex-direction: column;
      padding-top: 120px;
      box-sizing: border-box;
      justify-content: center;
      align-items: flex-start;
      height: 100vh;
      min-height: 800px;
      position: relative;
      .txt {
        @include gotham();
        font-weight: bold;
        font-size: 80px;
        color: $red;
        line-height: 100px;
      }
    }*/
    .video-item {
      position: absolute;
      top: 0;
      left: 0;
      min-width: $globalWidth;
      height: 100%;
      width: 100%;
      video {
        min-width: 100%;
        min-height: 100%;
      }
    }
    /* .bg {
      position: absolute;
      top: 0;
      left: 0;
      min-width: $globalWidth;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
    }*/
  }
  .title-area {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 145px;
    &.mt0 {
      margin-top: 0;
    }
    &.type2 {
      margin-top: 156px;
    }
    .title {
      display: block;
      line-height: 74px;
      font-size: 48px;
      font-weight: bold;
      color: #eb1624;
      span {
        color: #a0a0a0;
      }
    }
    .sub-title {
      display: block;
      @include montserrat();
      font-size: 36px;
      font-weight: 400;
      color: #000;
      & + .more {
        margin-bottom: 8px;
      }
    }
    .more {
      display: inline-flex;
      margin-bottom: 11px;
      @include montserrat();
      font-weight: 300;
      color: #000;
      font-size: 18px;
      line-height: 18px;
      border-bottom: 1px solid #000;
    }
  }
  .swiper-area {
    display: flex;
    align-items: center;
    margin-top: 110px;
    .swiper-item {
      flex: 0 0 440px;
      width: 440px;
      & + .swiper-item {
        position: relative;
        &:before {
          position: absolute;
          top: 120px;
          left: -12px;
          content: "";
          display: block;
          width: 24px;
          height: 24px;
          border-radius: 100%;
          background: #ee2c3c;
        }
      }
      .about-item {
        display: block;
        color: #000;

        .thumb {
          display: block;
          width: 260px;
          height: 260px;
          margin: 0 auto;
          border-radius: 100%;
          overflow: hidden;
          text-align: center;
          overflow: hidden;
          transition: transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955),
            -webkit-transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
          img {
            vertical-align: top;
            object-fit: cover;
            transform: scaleX(1);
            transition: opacity 0.5s ease 0.1s,
              transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955),
              -webkit-transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
          }
        }
        .title {
          display: block;
          margin-top: 40px;
          @include montserrat();
          text-align: center;
          font-size: 24px;
          line-height: 30px;
          font-weight: bold;
        }
        &:hover {
          .thumb {
            transform: scale3d(0.95, 0.95, 1);
            img {
              transform: scale3d(1.15, 1.15, 1);
            }
          }
        }
      }
    }
  }
  .work-list {
    @include clearFix();
    margin-top: 60px;
    padding: 0 44px;
    &:before {
      content: "";
      height: 1px;
    }
    li {
      width: calc((100% - 32px) / 2);
      margin-top: 32px;

      a {
        position: relative;
        display: block;
        height: 100%;
        font-family: "Noto Sans KR", Montserrat, sans-serif;
        .thumb {
          overflow: hidden;
          height: 100%;
          transition: transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955),
            -webkit-transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
          img {
            height: 100%;
            vertical-align: top;
            object-fit: cover;
            transform: scaleX(1);
            transition: opacity 0.5s ease 0.1s,
              transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955),
              -webkit-transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
          }
        }
        .title {
          @include montserrat();
          position: absolute;
          top: 60px;
          left: 42px;
          display: block;
          padding-right: 20px;
          font-size: 50px;
          letter-spacing: -0.01em;
          font-weight: bold;
          color: #fff;
          opacity: 0;
          word-break: keep-all;
          transition: opacity 0.3s ease;
          i {
            display: block;
            width: 12px;
            height: 12px;
            margin-left: 7px;
            border-radius: 100%;
            background: #fff;
            word-break: break-word;
          }
          span {
            margin-top: 14px;
            display: block;
            line-height: 64px;
          }
        }
        &:hover {
          .thumb {
            transform: scale3d(0.95, 0.95, 1);
            img {
              transform: scale3d(1.15, 1.15, 1);
            }
          }
          .title {
            opacity: 1;
          }
          /*.info {
          transform: scale3d(0.95, 0.95, 1);
        }*/
        }
      }
    }
    li:first-child {
      margin-top: 0 !important;
    }
    li:nth-child(2) {
      margin-top: 0;
      height: 757px;
    }
    li:nth-child(2n + 1) {
      float: left;
      clear: left;
    }
    li:nth-child(2n) {
      float: right;
      clear: right;
    }
  }
  .magazine-list {
    display: flex;
    margin-top: 110px;
    padding-bottom: 160px;
    li {
      width: calc((100% - 120px) / 3);
      box-sizing: border-box;
      margin-right: 60px;
      &:last-child {
        margin-right: 0;
      }
    }
    a {
      position: relative;
      display: block;
      min-height: 362px;
      box-sizing: border-box;
      padding-bottom: 29px;
      word-break: keep-all;
      .num {
        display: block;
        @include montserrat();
        font-size: 30px;
        line-height: 20px;
        font-weight: bold;
        color: #000;
      }
      .title {
        position: relative;
        display: block;
        height: 148px;
        box-sizing: border-box;
        margin-top: 28px;
        padding-top: 26px;
        font-size: 24px;
        line-height: 40px;
        font-weight: bold;
        color: #000;
        &:before {
          position: absolute;
          top: 0;
          left: 0;
          content: "";
          display: block;
          width: 12px;
          height: 12px;
          border-radius: 100%;
          background: #ee2c3c;
        }
      }
      .date {
        display: block;
        margin-top: 16px;
        @include montserrat();
        font-size: 14px;
        line-height: 14px;
        word-break: break-word;
        color: #b7b7b7;
      }
      .desc {
        display: block;
        margin-top: 15px;
        font-size: 14px;
        line-height: 24px;
        color: #000;
      }
      .more {
        @include montserrat;
        position: absolute;
        bottom: 0;
        left: 0;
        display: inline-block;
        margin: 30px 0 0;
        font-size: 14px;
        line-height: 20px;
        color: #000;
        transition: all 0.3s ease-in-out;
        &::before {
          display: block;
          content: "";
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          height: 1px;
          background: #000;
          transition: all 0.3s ease-in-out;
        }
        &::after {
          display: block;
          content: "";
          position: absolute;
          right: 0;
          bottom: 0;
          z-index: -1;
          width: 12px;
          height: 1px;
          background: #ee2c3c;
          transition: all 0.3s ease-in-out;
        }
      }
      &:hover,
      &:focus {
        .more {
          color: #ee2c3c;
          &::before {
            background: #ee2c3c;
          }
          /*
                    &::after {
                      transform-origin: right bottom;
                      transform: rotate(45deg);
                    }*/
        }
      }
    }
  }
</style>
